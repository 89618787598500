import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"
import { css } from "@emotion/react"

export const StyledDashboard = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;

  form {
    .float-container {
      margin-bottom: 0;
    }
    .offerame {
      margin-bottom: 1.25rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 3.5rem auto;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 7rem auto;
  }
`

export const StyledDashboardDisabledWarning = styled.div`
  max-width: ${(p) => p.theme.breakpoints.tablet};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  min-height: 12rem;

  i.fal {
    font-size: 2.2rem;
    opacity: 0.25;
    margin-bottom: 1.2rem;
  }

  p {
    font-size: 0.95rem;
  }
`

export const StyledDashboardGrid = styled.div`
  margin: auto;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: ${(p) => p.theme.breakpoints.tablet};
    padding: 0 1.3rem 0 1.3rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: ${(p) => p.theme.breakpoints.desktop};
    padding: 1.5rem 1.9rem 0 1.9rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: ${(p) => p.theme.breakpoints.wide};
    margin-top: 0;
    padding: 0 5.9rem 0 5.9rem;
  }
`

export const StyledContent = styled.div`
  &.no-margin {
    margin-left: 0;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-left: 12.125rem;
  }

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-left: 13.4375rem;
  }
  @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-left: 25rem;
  }
`
export const StyledNavSection = styled.div`
  margin-top: 8.5rem;
  background-color: ${(p) => p.theme.colors.white};
  transform: translateX(0);
  border-right: 0.0625rem solid ${(p) => p.theme.colors.tableItemBorder};
  padding: 1rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 1.5rem 0.5rem 0.5rem 1rem;
  }
`

export const StyledPage = styled.div`
  margin: 0;
  padding: 0 0 5rem;

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}

  .merchant-currency {
    display: inline-flex;
    padding-bottom: 1.875rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 5.75rem 1rem 0 1rem;

    &.download-center {
      margin: 5.75rem 1rem 0 1rem;
    }
  }

  .campaigns-tab {
    display: grid;
    grid-template-columns: auto 8.75rem 6.25rem 5.3125rem;
    gap: 1rem;
    grid-gap: 1rem;
  }
  .react-tabs {
    li {
      margin-bottom: unset;
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      color: ${(p) => p.theme.colors.disabled};
      padding: 0;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 2rem;
        line-height: 2.625rem;
      }
    }

    .react-tabs__tab--selected {
      color: ${(p) => p.theme.colors.pitchBlack};
      text-decoration: underline;
      text-underline-offset: 0.5rem;
    }
  }

  #charities {
    margin: 2rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 5.75rem 0 0 1rem;
    .react-tabs {
      margin: 1.5rem 0 1.375rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: unset;
    .campaigns-tab {
      display: flex;
    }
    .react-tabs {
      margin: 1.5rem 0 1.875rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    .campaigns-tab {
      gap: 2.5rem;
    }
    .react-tabs {
      margin: 2.5rem 0 3.125rem;
    }
  }
`

export const StyledPageTitle = styled.div`
  grid-area: title;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  h1 {
    font-size: 2rem;
    font-weight: ${(p) => p.theme.fontWeights.semiBold};
    flex: 2 1 auto;
    margin-bottom: 0;
  }

  svg {
    font-size: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }

  .create-button {
    font-size: 0.75rem;
    white-space: nowrap;
    padding: 0;
  }

  button {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.blue};
    text-transform: uppercase;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 2.5rem;

    &.download-center {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    h1 {
      font-size: 3rem;
    }
    .create-button {
      font-size: 1.25rem;
    }
    svg {
      font-size: 1.5rem;
      height: 1.5rem;
    }
  }

  &.developers {
    h1 {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      font-style: ${(p) => p.theme.fontStyles.normal};

      font-size: 1.5rem;
      line-height: 2.125rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 2rem;
        line-height: 2.75rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 3rem;
        line-height: normal;
      }
    }
  }

  &.referral-code {
    h1 {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};
      font-style: ${(p) => p.theme.fontStyles.normal};

      font-size: 1.5rem;
      line-height: 2.125rem;

      width: unset;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 2rem;
        line-height: 2.75rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 3rem;
        line-height: normal;
      }
    }
  }
`

export const StyledProfileTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
`

export const StyledPageContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  grid-area: content;
  display: block;
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `}
  h3 {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    margin-bottom: 1.25rem;
  }

  button {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.blue};
    font-size: 1rem;
    line-height: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`

export const StyledDonationReportSection = styled.div`
  justify-content: space-between;
  .react-datepicker-wrapper input {
    height: 2.5rem;
  }

  p {
    font-size: 1rem;
    color: ${(p) => p.theme.colors.disabled};
  }
  h3 {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    margin-bottom: 1.25rem;
  }

  button {
    margin: 0 !important;
  }
`
export const StyledReportOptions = styled.div`
  
  div {
    margin-bottom: 1rem;
    max-width: 400px;
  }
  .react-datepicker-wrapper .react-datepicker__input-container input {
    font-size: 1rem!important;
    color: rgba(122, 119, 118, 1)
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: grid;
    gap: 1rem;
    grid-template-columns: 3fr 3fr 3fr;
    grid-template-areas: "search date submit";
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-template-columns: 2fr 3fr 3fr;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 3fr 3fr 3fr;
  }

  button {
    height: 2.5rem;
  }

  .react-datepicker-wrapper {
    height: 2.5rem !important;
    width: 100%;
    grid-area: date;
  }

  .date-picker-input > input {
    height: 2.5rem !important;
    font-size: 0.75rem !important;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1rem !important;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 3.375rem !important;
      font-size: 1.5rem !important;
    }
    max-width: unset;
  }

  .campaigns-select {
    grid-area: search;
    width: 100%;
  }

  .generate-report {
    grid-area: submit;
    width: 100%;
  }

  .generate-report > button {
    width: 100%;
  }
`

export const StyledSpacer = styled.div`
  flex: 2 1 auto;
  width: 100%;
`

export const StyledPageControls = styled.div`
  grid-area: controls;
  align-self: stretch;
  justify-self: stretch;

  display: flex;
  align-items: center;
  justify-content: ${(p) => p.justify || "flex-end"};
  padding: 1rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    justify-content: flex-start;
  }

  button {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.blue};
    font-size: 1rem;
    line-height: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`

export const StyledPageActions = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: ${(p) => p.align || "flex-end"};
  margin-bottom: 2rem;

  button {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.blue};
    font-size: 1rem;
    line-height: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }

  button:disabled {
    background-color: ${(p) => p.theme.colors.disabled};
  }

  .submit-for-review-button,
  .btn-save-merchant-offer {
    color: ${(p) => p.theme.colors.white};
  }
`

export const StyledBackLink = styled(Link)`
  position: relative;
  display: block;
  text-align: right;
  margin-bottom: 1rem;

  svg {
    font-size: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
  }
`

export const StyledModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;

  button {
    min-width: 6.25rem;
    height: 2.8125rem;
    margin: unset;
    padding: unset;
  }

  .not-mobile-btn-developer,
  .not-mobile-btn-user {
    position: unset;
    margin-bottom: unset;
  }

  .not-mobile-btn-user {
    width: 100%;
  }

  .mobile-btn-developer,
  .mobile-btn-user {
    position: absolute;
    margin-left: 0;
    margin-right: 0;
    bottom: 1rem;
    width: 88vw;
    white-space: normal;
  }
`

export const StyledFormSectionTitle = styled.p`
  font-size: 1.25rem;
  font-weight: ${(p) => p.theme.fontWeights.bold};
  color: ${(p) => p.theme.colors.gondola};
  margin: 2rem 0 1rem 0;
`

export const StyledFormSectionHelpText = styled.p`
  font-size: 1rem;
  color: ${(p) => p.theme.colors.shuttleflGray};
  margin-bottom: 1rem;

  a {
    display: block;
    color: ${(p) => p.theme.colors.blue};
  }
`

export const StyledAccordionContent = styled.div`
  padding: 2rem;
`

export const StyledFeatureCampaigns = styled.div`
  padding: 6.25rem 0;
`

export const StyledForm = styled.form`
  hr {
    margin: 2rem 0;
  }
  & > button {
    margin: 1rem 0 0 0;
  }
  &.modal-form {
    .float-container {
      margin-bottom: 2rem;
    }
    button {
      margin-left: auto;
    }
  }
  .react-select__single-value,
  .react-select__placeholder {
    font-size: 1rem;
  }
`

export const StyledFormSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.justify-end {
    justify-content: flex-end;
  }

  .float-container.select-field {
    min-width: 40%;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.logo-wrapper .image-selector div {
    line-height: 1.5;
    max-height: 12.5rem;
    max-width: 12.5rem;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  ${(p) =>
    p.donor &&
    css`
      img {
        max-width: 11.25rem;
      }
    `};

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    ${(p) =>
      p.horizontal &&
      css`
        display: block;
      `};

    .image-selector {
      width: 100%;
    }
  }
`

export const StyledFormSectionHeading = styled.div`
  h3 {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    margin-bottom: 1.25rem;
  }
  p {
    font-size: 1rem;
    color: ${(p) => p.theme.colors.disabled};
  }
`
export const StyledAboutSection = styled(StyledFormSectionHeading)`
  p {
    margin-top: 2rem;
  }

  .image-selector img {
    width: 100%;
  }
`
export const StyledPACWalletTableWrapper = styled.div``

export const StyledPACWalletTableExploreHeader = styled.div`
  p,
  a {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.5rem;
      line-height: 2.125rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }

  a {
    color: ${(p) => p.theme.colors.carrot};
  }
`

export const StyledPACWalletTableDonateHeader = styled.div`
  p,
  a {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin: 0.75rem 0 1.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.5rem;
      line-height: 2.125rem;
      margin: 0.875rem 0 2rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.75rem;
      margin: 1.5rem 0 3rem;
    }
  }

  a {
    color: ${(p) => p.theme.colors.blue};
  }
`
export const StyledPACWalletTableFilters = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-bottom: 2.125rem;
  }

  > div {
    //checkbox filters
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.25rem;
    column-gap: 1rem;

    .filter-checkbox {
      font-family: ${(p) => p.theme.fontFamilies.regular};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.liteMedium};
      color: ${(p) => p.theme.colors.black};

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 0 0.6875rem 0 0;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.5rem;
        line-height: 2.25rem;
        margin: 0 2.625rem 0 0;
      }

      > span {
        top: 0.1rem;

        @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
          top: 0.6rem;
        }
      }
    }
  }

  .filter-button {
    p.link-button-text span {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }
    svg {
      display: none;
    }
  }
`

export const StyledViewAll = styled.div`
  text-align: center;
  margin-bottom: 2.875rem;
`
export const StyledSliceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 1rem;

  div {
    width: 100%;
    text-align: right;
  }

  h3 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.colors.black};
    max-width: 53vw;
    word-break: break-word;
    margin-right: 0.3rem;
  }

  ${(p) =>
    p.carrot &&
    css`
      button {
        text-align: right;
      }
      p.link-button-text {
        color: ${p.theme.colors.carrot};
        max-width: 42vw;
      }
    `}

  .filter-button {
    p.link-button-text span {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-style: ${(p) => p.theme.fontStyles.normal};
      font-weight: ${(p) => p.theme.fontWeights.semiBolder};

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        font-size: 1rem !important;
        line-height: 1.5rem;
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      }

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        font-size: 1.25rem !important;
        line-height: 1.75rem;
      }
    }
    svg {
      display: none;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 0.1875rem 0 0;
    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .link-button-text span {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding-top: 0.0625rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding-top: 1.5rem;
    h3 {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }
`

export const StyledTransactionsShow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 1rem;
`

export const StyledTransactions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1rem;
`

export const StyledTransactionsHide = styled.div`
  svg {
    height: 2rem;
    width: 1rem;
    line-height: 1.5rem;
    cursor: pointer;
  }
`

export const StyledFilters = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.25rem;
`

export const StyledMobileFilterMenu = styled.div`
  .mobile-filters {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.black};
    font-size: 1rem;
    line-height: 1.5rem;

    .filter-checkbox {
      padding: 0;
      width: 100%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > span {
        top: 0;
        display: block;
        position: relative;
        float: right;
        min-width: 1.25rem;
      }
    }
    .last {
      min-height: 1.25rem;
      padding-bottom: 0.5rem;
    }
  }
`

export const StyledMobileHr = styled.hr`
  margin: 0.5rem 0;
`

export const StyledWarningWrapper = styled.div`
  border: 0.0625rem solid ${(p) => p.theme.colors.red};
  border-radius: 0.3125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: block;

    div:last-of-type {
      display: flex;
      justify-content: right;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    h3 {
      text-align: center;
    }

    button {
      width: 100%;
    }
  }
`
export const StyledModalLongConfirm = styled.p`
  white-space: pre-line;
  line-height: 1.5;
  margin-right: 1rem;
`

export const StyledModalContainer = styled.div`
  text-align: left;
  textarea {
    height: 13.5rem;
  }
  .textarea-counter {
    bottom: 1rem !important;
  }

  .caption {
    font-family: ${(p) => p.theme.fontFamilies.headlineBig};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    color: ${(p) => p.theme.colors.disabled};
    margin-bottom: unset;

    @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1.25rem;
      letter-spacing: 0.03em;

      margin: 1.5rem 0 0.5rem;
    }

    @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.02em;

      margin: 2rem 0 0.5rem;
    }
  }

  .text {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.pitchBlack};
    white-space: normal;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.625rem;

      margin: 1.25rem 0 2rem;
      width: 29.0625rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;

      margin: 1.5rem 0 2.5rem;
      width: 43.0625rem;
    }
  }

  .btn-stop-campaign {
    width: 100%;

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.white};
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.04em;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      line-height: 1.375rem;
      height: 3.375rem;
    }

    &.step1 {
      margin-top: 2.5rem;
    }

    &.step2 {
    }
  }

  &.stop {
    padding: 0.75rem;
    .caption {
      margin: 1.5rem 0 0.5rem;
    }
    .step1 {
      margin-top: 1.5rem;
    }
    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      padding: 1.75rem;
    }
  }
`

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 3rem;
  svg {
    font-size: 1.5rem;
    height: 1.5rem;
  }
  button {
    padding: 0;
  }
  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBold};
    color: ${(p) => p.theme.colors.pitchBlack};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.625rem;
      svg {
        font-size: 2rem;
        height: 2rem;
      }
    }
  }

  &.stop-header {
    h4 {
      max-width: 60%;
    }
    svg {
      position: relative;
      top: -0.875rem;
      margin: 0;
    }
  }
`
